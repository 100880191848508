<aside class="main-sidebar">
  <section class="sidebar" #sidebarElement [style.height.px]="sidebarHeight" [style.overflow]="sidebarOverflow">
    <ng-content></ng-content>
  	<ul class="sidebar-menu">
      <ng-container *ngFor="let item of menu">
        <ng-container *ngTemplateOutlet="sidebarInner; context: {item: item}"></ng-container>
      </ng-container>
  	</ul>
  </section>
</aside>

<ng-template #sidebarInner let-item="item">
  <li [class.active]="item.isActive" [class.header]="item.separator" [class.menu-open]="!item.isCollapsed">
    <span *ngIf="item.separator">{{item.label}}</span>
    <a *ngIf="!item.separator && item.route" [routerLink]="item.route">
    	<i [class]="getIconClasses(item)"></i><span>{{item.label}}</span>
    	<span *ngIf="item.children || item.pullRights" class="pull-right-container">
    		<span *ngFor="let rightItem of item.pullRights" class="pull-right {{rightItem.classes}}">{{rightItem.text}}</span>
    	  <i *ngIf="!item.pullRights" class="fa fa-angle-left pull-right"></i>
    	</span>
    </a>
    <a *ngIf="!item.separator && !item.route" href="#" [mkMenuToggle]="item">
    	<i [class]="getIconClasses(item)"></i><span>{{item.label}}</span>
    	<span *ngIf="item.children || item.pullRights" class="pull-right-container">
    		<span *ngFor="let rightItem of item.pullRights" class="pull-right {{rightItem.classes}}">{{rightItem.text}}</span>
    	  <i *ngIf="!item.pullRights" class="fa fa-angle-left pull-right"></i>
    	</span>
    </a>
    <ul *ngIf="item.children" [mkCollapseAnimation]="item.isCollapsed" (mkCollapseAnimation.start)="visibilityStateStart($event)" class="treeview-menu">
      <ng-container *ngFor="let item of item.children">
        <ng-container *ngTemplateOutlet="sidebarInner; context: {item: item}"></ng-container>
      </ng-container>
    </ul>
  </li>
</ng-template>
