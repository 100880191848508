<div [ngClass]="styleClass" [mkColor]="backgroundColor" mkColorProperty="background-color">
  <span class="info-box-icon" [mkColor]="iconBackgroundColor" mkColorProperty="background-color">
    <i [ngClass]="iconStyleClass" [mkFontColor]="iconColor"></i>
  </span>
  <div class="info-box-content">
    <span *ngIf="header || boxInfoHeaderDirective" [ngClass]="headerStyleClass" [mkFontColor]="headerColor">
      {{header}}
      <ng-content select="mk-box-header"></ng-content>
    </span>
    <span [ngClass]="contentStyleClass" [mkFontColor]="contentColor">
      <ng-container *ngIf="boxInfoHeaderDirective || boxInfoContentDirective || boxInfoFooterDirective; else noDirective">
        <ng-content select="mk-box-content"></ng-content>
      </ng-container>
      <ng-template #noDirective>
        <ng-content></ng-content>
      </ng-template>
    </span>
    <div *ngIf="progressWidth" class="progress">
      <div class="progress-bar" [mkColor]="progressBarBg" mkColorProperty="background-color" [style.width.%]="progressWidth"></div>
    </div>
    <span *ngIf="footer || boxInfoFooterDirective" [ngClass]="footerStyleClass" [mkFontColor]="footerColor">
      {{footer}}
      <ng-content select="mk-box-footer"></ng-content>
    </span>
  </div>
</div>
