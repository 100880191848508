

  <div style="min-height: 100vh; overflow: hidden;" class="hold-transition login-page">
    <div class="login-box">
      <div class="login-logo">
        <p >Reset Password</p>
      </div>
      <!-- /.login-logo -->
      <div class="login-box-body">
        <p class="login-box-msg">Reset Your Password. </p>
  
        <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
          <div class="form-group has-feedback">
            <input type="password" formControlName="password" class="form-control" placeholder="Password">
            <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
            <p *ngIf="resetPasswordForm.controls.password.touched || isFormSubmitted">
              <app-form-validation-errors errorPrefix="Password " minLength=10 maxLength=50 [errors]="resetPasswordForm.get('password').errors"></app-form-validation-errors>
            </p>
          </div>

          <div class="form-group has-feedback">
            <input type="password" formControlName="repassword" class="form-control" placeholder="Re-Password">
            <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
            <p *ngIf="resetPasswordForm.controls.repassword.touched || isFormSubmitted">
              <app-form-validation-errors errorPrefix="Re-Password " minLength=10 maxLength=50 [errors]="resetPasswordForm.get('repassword').errors"></app-form-validation-errors>
            </p>
          </div>
         
          <div class="row">       
           
            <div class="col-xs-12">
              <button type="submit" [disabled]="!resetPasswordForm.valid" [ngClass]="{ 'submit-btn-disable': !resetPasswordForm.valid }" class="btn btn-primary btn-block btn-flat">Submit</button>
              
            </div>
            <!-- /.col -->
          </div>
        </form>
  
        <div class="social-auth-links text-center"></div>
        <!-- /.social-auth-links -->
     
       
     
      </div>
      <!-- /.login-box-body -->
      <!--<app-forgot-password></app-forgot-password>-->
    </div>
  </div>
  