<div *ngIf="!removed" [mkColor]="boxColor" mkColorProperty="border-color" mkColorPrefix="box" [ngClass]="styleClass" [class.collapsed-box]="isCollapsed && !isCollaping" [class.box-solid]="isSolid" [mkCollapseAnimation]="remove" (mkCollapseAnimation.done)="removedDone($event)">
  <div *ngIf="header || boxHeaderDirective" [ngClass]="headerStyleClass" [mkColor]="boxColor" [mkColorCondition]="isSolid" mkColorProperty="background-color" [class.with-border]="headerBorder">
    <h3 class="box-title" [mkFontColor]="headerColor">
      {{header}}
      <ng-content select="mk-box-header"></ng-content>
    </h3>
    <div class="box-tools pull-right">
      <ng-content select="mk-box-tools"></ng-content>
      <button *ngIf="isCollapsable" type="button" [ngClass]="buttonsStyleClass" #toggleButtonElement>
        <i class="fa" [ngClass]="{'fa-plus': isCollapsed, 'fa-minus': !isCollapsed}"></i>
      </button>
      <button *ngIf="isRemovable" type="button" [ngClass]="buttonsStyleClass" #removeButtonElement>
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <div [ngClass]="contentStyleClass" [mkFontColor]="contentColor" [mkCollapseAnimation]="isCollapsed" (mkCollapseAnimation.start)="onCollapseStart($event)" (mkCollapseAnimation.done)="onCollapseDone($event)">
    <div class="box-body">
      <ng-container *ngIf="boxHeaderDirective || boxContentDirective || boxFooterDirective || boxToolsDirective; else noDirective">
        <ng-content select="mk-box-content"></ng-content>
      </ng-container>
      <ng-template #noDirective>
        <ng-content></ng-content>
      </ng-template>
    </div>
    <div *ngIf="footer || boxFooterDirective" [ngClass]="footerStyleClass" [mkFontColor]="footerColor">
      {{footer}}
      <ng-content select="mk-box-footer"></ng-content>
    </div>
  </div>
  <div *ngIf="isLoading" class="overlay">
    <i [ngClass]="loadingStyleClass" [mkFontColor]="loadingColor"></i>
  </div>
</div>
