<ng-container *ngIf="customLayout; else noCustomLayout">
  <app-page-loader></app-page-loader>
  <router-outlet></router-outlet>
</ng-container>
<ng-template #noCustomLayout>
  <mk-layout-wrapper>
    <mk-layout-header logoLink="/">
      <mk-layout-header-logo><b>TuCasa </b>Control Panel</mk-layout-header-logo>
      <mk-layout-header-logo-mini><b>T</b>CP</mk-layout-header-logo-mini>
      <app-header-inner></app-header-inner>
    </mk-layout-header>
    <mk-layout-sidebar-left>
      <app-sidebar-left-inner></app-sidebar-left-inner>
    </mk-layout-sidebar-left>
    <mk-layout-sidebar-right>
      <app-sidebar-right-inner></app-sidebar-right-inner>
    </mk-layout-sidebar-right>
    <mk-layout-content>
      <app-page-loader></app-page-loader>
      <div mk-layout-content-before-header>
        <div *mkLoadingPage="{checkPendingHttp: true, checkPendingRoute: true}">
          <mk-material-bar></mk-material-bar>
        </div>
      </div>
      <router-outlet></router-outlet>
    </mk-layout-content>
    <mk-layout-footer>
      <mk-layout-footer-left>
        <strong>Powered by <a href="javascript:void(0)">TuCasa</a>.</strong>
      </mk-layout-footer-left>
      <mk-layout-footer-right>
        <b>Version</b> 1.0.0
      </mk-layout-footer-right>
    </mk-layout-footer>
  </mk-layout-wrapper>
</ng-template>
