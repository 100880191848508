<ng-template #innerTemplate>
  <ng-container *ngIf="this.dropdownToggleComponent && this.dropdownToggleComponent.toggleElement; else noToggleElement" [ngTemplateOutlet]="dropdownToggleComponent.templateRef"></ng-container>
  <ng-template #noToggleElement>
    <button *ngIf="toggleText || dropdownToggleComponent" [mkColor]="buttonBackgroudColor" mkColorProperty="background-color" mkColorPrefix="btn" [ngClass]="buttonStyleClass" #toggleElement>
      {{toggleText}}
      <ng-container [ngTemplateOutlet]="dropdownToggleComponent?.templateRef"></ng-container>
    </button>
  </ng-template>
  <ul [ngClass]="contentStyleClass" [mkCollapseAnimation]="isCollapsed" (mkCollapseAnimation.start)="onCollapseStart($event)" (mkCollapseAnimation.done)="onCollapseDone($event)">
    <ng-container *ngIf="dropdownMenuComponent; else noDropdownMenuComponent" [ngTemplateOutlet]="dropdownMenuComponent.templateRef"></ng-container>
    <ng-template #noDropdownMenuComponent>
      <ng-content></ng-content>
    </ng-template>
  </ul>
</ng-template>

<div *ngIf="isWrapper; else noWrapper" [ngClass]="styleClass">
  <ng-container *ngTemplateOutlet="innerTemplate"></ng-container>
</div>

<ng-template #noWrapper>
  <ng-container *ngTemplateOutlet="innerTemplate"></ng-container>
</ng-template>
