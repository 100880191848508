<div [ngClass]="styleClass">
  <div *ngFor="let accordion of accordionComponents" class="panel box" [mkColor]="accordion.borderColor" mkColorProperty="border-top-color" mkColorPrefix="box">
    <div [ngClass]="accordion.headerStyleClass" [class.no-border]="accordion.isCollapsed && !accordion.isCollapsing">
      <h4 class="box-title">
        <a [mkAccordionToggle]="accordion" href="#" [mkFontColor]="accordion.headerStyleColor" [class.collapsed]="accordion.isCollapsed">
          {{accordion.header}}
          <ng-template *ngIf="!accordion.header" [ngTemplateOutlet]="accordion.accordionHeaderComponent?.templateRef"></ng-template>
        </a>
      </h4>
    </div>
    <div class="panel-collapse" [mkCollapseAnimation]="accordion.isCollapsed" (mkCollapseAnimation.start)="onCollapseStart($event, accordion)" (mkCollapseAnimation.done)="onCollapseDone($event, accordion)">
      <div [ngClass]="accordion.contentStyleClass" [mkFontColor]="accordion.contentColor">
        <ng-template [ngTemplateOutlet]="accordion.contentTemplateRef"></ng-template>
      </div>
    </div>
  </div>
</div>
