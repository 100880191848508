<div class="user-panel">
  <div class="pull-left image">
    <img src="{{adminData?.profile_pic}}" onerror="this.src='/assets/img/avatar5.png'" class="img-circle" alt="User Image">
  </div>
  <div class="pull-left info">
    <p>{{adminData.name}}</p>
    <a href="javascript:void(0)"><i class="fa fa-circle text-success"></i> Online</a>
  </div>
</div>
<!--<form action="#" method="get" class="sidebar-form">
  <div class="input-group">
    <input type="text" name="q" class="form-control" placeholder="Search...">
    <span class="input-group-btn">
      <button type="submit" name="search" id="search-btn" class="btn btn-flat"><i class="fa fa-search"></i>
      </button>
    </span>
  </div>
</form>-->
