<div [ngClass]="styleClass" [mkColor]="backgroundColor" mkColorProperty="background-color">
  <div class="inner">
    <h3 *ngIf="header || boxSmallHeaderDirective" [ngClass]="headerStyleClass" [mkFontColor]="headerColor">
      {{header}}
      <ng-content select="mk-box-small-header"></ng-content>
    </h3>
    <p [ngClass]="contentStyleClass" [mkFontColor]="contentColor">
      <ng-container *ngIf="boxSmallHeaderDirective || boxSmallContentDirective || boxSmallFooterDirective; else noDirective">
        <ng-content select="mk-box-small-content"></ng-content>
      </ng-container>
      <ng-template #noDirective>
        <ng-content></ng-content>
      </ng-template>
    </p>
  </div>
  <div *ngIf="iconStyleClass" class="icon">
    <i [ngClass]="iconStyleClass" [mkFontColor]="iconColor"></i>
  </div>
  <span *ngIf="footer || boxSmallFooterDirective" [ngClass]="footerStyleClass" [mkFontColor]="footerColor">
    {{footer}}
    <ng-content select="mk-box-small-footer"></ng-content>
  </span>
</div>
